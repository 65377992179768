/* stylelint-disable selector-class-pattern */
@import 'font'; // Our local font declarations
@import 'typography';
@import '~@design-system/colour-tokens/colour';

$boostrapInvalid: #dc3545;

// https://getbootstrap.com/docs/5.0/customize/sass/#importing

// 1. Add any bootstrap SCSS variable overrides here - https://getbootstrap.com/docs/5.3/customize/options/
$spacer: 24px;

// https://getbootstrap.com/docs/5.0/utilities/spacing/#sass
$spacer1: $spacer * 0.25; // 8px
$spacer2: $spacer * 0.5; // 12px
$spacer3: $spacer; // 24px
$spacer4: $spacer * 1.5; // 36px
$spacer5: $spacer * 3; // 72px

// 2. Import bootstrap which now uses our SCSS variables declared above
@import '../../../node_modules/bootstrap/scss/bootstrap';

// 3. Override bootstrap CSS variables and component classes
:root,
[data-bs-theme='light'] {
    // higher than WYSIWYG editor header
    --date-picker-z-index: 3;
    --bs-font-sans-serif: inter, sans-serif, system-ui;
    // main colours
    $primaryColor: #230831;
    $secondaryColor: #eeccff;
    $tertiaryColor: #4f395a;
    $destructiveColor: #d41726;
    $destructiveHoverColor: #dd4551;
    $pageColor: #f2eef2;
    $focusColor: #3a81ff;
    $whiteColor: #ffffff;
    $blackColor: #000000;

    // text colours
    $lightTextColor: #ffffff;
    $darkTextColor: #000000;
    $secondaryTextColor: #595652;
    $modalBodyTextColor: #71717a;
    $tableHeaderTextColor: #71717a;
    $preSelectTextColor: #71717a;

    $inputPadding: 16px;
    $iconWidth: 16px;
    $iconMarginRight: 8px;

    // borders and box shadows
    $formControlBorderColor: #dee2e6;
    $navBorderColor: #e4e4e733;
    // prettier-ignore
    $focusBoxShadow: 0 0 0 2px #ffffff, 0 0 0 4px $focusColor;

    // button styles
    $btnSecondaryBgColor: #ffffff;
    $btnSecondaryTextColor: #{$darkTextColor};
    $btnSecondaryHoverActiveColor: #{$secondaryColor};
    $btnOutlineBgColor: transparent;
    $btnOutlineTextColor: #{$darkTextColor};
    $btnOutlineBorderColor: #{$primaryColor};
    $btnOutlineHoverBorderColor: #{$tertiaryColor};
    $btnOutlineHoverTextColor: #{$tertiaryColor};
    $btnGhostBgColor: transparent;
    $btnGhostTextColor: #{$darkTextColor};
    $btnGhostHoverTextColor: #{$tertiaryColor};
    $btnDestructiveBgColor: #{$destructiveColor};
    $btnDestructiveTextColor: #{$lightTextColor};
    $btnDestructiveHoverBgColor: #{$destructiveHoverColor};

    // Override accordion styles
    .accordion {
        --bs-accordion-active-bg: #ffffff;
        --bs-accordion-active-color: inherit;
        --bs-accordion-btn-focus-border-color: #ffffff;
        --bs-accordion-btn-focus-box-shadow: $focusBoxShadow;
        --bs-accordion-border-radius: 0;
        --bs-accordion-inner-border-radius: 0;
        --bs-accordion-btn-padding-x: 0;
        --bs-accordion-body-padding-x: 0;
        @include typography(small-text);

        .accordion-item {
            border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
            border-right: 0;
            border-bottom: 0;
            border-left: 0;

            &:first-child {
                border: 0;
            }

            :focus {
                border-radius: 6px;
            }
        }

        .accordion-header {
            border: 0;
        }

        .accordion-collapse {
            border: 0;
        }

        .accordion-button {
            @include typography(small-text-strong);
            box-shadow: none;

            &:focus-visible {
                box-shadow: $focusBoxShadow;
            }

            &::after {
                background-image: url(../icons/chevron-down.svg);
                background-size: 20px;
                opacity: 0.6;
            }
        }
    }

    // Override button styles
    .btn {
        --bs-btn-padding-x: 16px;
        --bs-btn-padding-y: 8px;
        --bs-btn-focus-box-shadow: #{$focusBoxShadow};
        @include typography(small-text-strong);
    }

    .btn-sm {
        --bs-btn-padding-x: 12px;
        --bs-btn-padding-y: 8px;
        --bs-btn-focus-box-shadow: #{$focusBoxShadow};
        @include typography(tiny-text);
    }

    // Override default bootstrap button themes - primary, secondary, our own variations 'outline', 'ghost' and 'destructive'
    .btn-primary {
        --bs-btn-color: #{$lightTextColor};
        --bs-btn-bg: #{$primaryColor};
        --bs-btn-border-color: #{$primaryColor};
        --bs-btn-hover-color: #{$lightTextColor};
        --bs-btn-hover-bg: #{$tertiaryColor};
        --bs-btn-hover-border-color: #{$tertiaryColor};
        --bs-btn-active-color: #{$lightTextColor};
        --bs-btn-active-bg: #{$primaryColor};
        --bs-btn-active-border-color: #{$primaryColor};
        --bs-btn-active-shadow: inset 0 3px 5px #00000020;
        --bs-btn-disabled-color: #{$lightTextColor};
        --bs-btn-disabled-bg: #{$primaryColor};
        --bs-btn-disabled-border-color: #{$primaryColor};
        --bs-btn-disabled-opacity: 0.5;
    }

    .btn-secondary {
        --bs-btn-color: #{$btnSecondaryTextColor};
        --bs-btn-bg: #{$btnSecondaryBgColor};
        --bs-btn-border-color: #{$btnSecondaryBgColor};
        --bs-btn-hover-color: #{$btnSecondaryTextColor};
        --bs-btn-hover-bg: #{$btnSecondaryHoverActiveColor};
        --bs-btn-hover-border-color: #{$btnSecondaryHoverActiveColor};
        --bs-btn-active-color: #{$btnSecondaryTextColor};
        --bs-btn-active-bg: #{$btnSecondaryHoverActiveColor};
        --bs-btn-active-border-color: #{$btnSecondaryHoverActiveColor};
        --bs-btn-active-shadow: inset 0 3px 5px #994e4e20;
        --bs-btn-disabled-color: #{$btnSecondaryTextColor};
        --bs-btn-disabled-bg: #{$btnSecondaryBgColor};
        --bs-btn-disabled-border-color: #{$btnSecondaryBgColor};
        --bs-btn-disabled-opacity: 0.5;
    }

    .btn-outline {
        --bs-btn-color: #{$btnOutlineTextColor};
        --bs-btn-bg: #{$btnOutlineBgColor};
        --bs-btn-border-color: #{$btnOutlineBorderColor};
        --bs-btn-hover-color: #{$btnOutlineHoverTextColor};
        --bs-btn-hover-bg: #{$btnOutlineBgColor};
        --bs-btn-hover-border-color: #{$btnOutlineHoverBorderColor};
        --bs-btn-active-color: #{$btnOutlineTextColor};
        --bs-btn-active-bg: #{$btnOutlineBgColor};
        --bs-btn-active-border-color: #{$btnOutlineBorderColor};
        --bs-btn-active-shadow: inset 0 3px 5px #00000020;
        --bs-btn-disabled-color: #{$btnOutlineTextColor};
        --bs-btn-disabled-bg: #{$btnOutlineBgColor};
        --bs-btn-disabled-border-color: #{$btnOutlineBorderColor};
        --bs-btn-disabled-opacity: 0.5;
    }

    .btn-ghost {
        --bs-btn-color: #{$btnGhostTextColor};
        --bs-btn-bg: #{$btnGhostBgColor};
        --bs-btn-border-color: #{$btnGhostBgColor};
        --bs-btn-hover-color: #{$btnGhostHoverTextColor};
        --bs-btn-hover-bg: #{$btnGhostBgColor};
        --bs-btn-hover-border-color: #{$btnGhostBgColor};
        --bs-btn-active-color: #{$btnGhostTextColor};
        --bs-btn-active-bg: #{$btnGhostBgColor};
        --bs-btn-active-border-color: #{$btnGhostBgColor};
        --bs-btn-active-shadow: inset 0 3px 5px #00000020;
        --bs-btn-disabled-color: #{$btnGhostTextColor};
        --bs-btn-disabled-bg: #{$btnGhostBgColor};
        --bs-btn-disabled-border-color: #{$btnGhostBgColor};
        --bs-btn-disabled-opacity: 0.5;
    }

    .btn-destructive {
        --bs-btn-color: #{$btnDestructiveTextColor};
        --bs-btn-bg: #{$btnDestructiveBgColor};
        --bs-btn-border-color: #{$btnDestructiveBgColor};
        --bs-btn-hover-color: #{$btnDestructiveTextColor};
        --bs-btn-hover-bg: #{$btnDestructiveHoverBgColor};
        --bs-btn-hover-border-color: #{$btnDestructiveHoverBgColor};
        --bs-btn-active-color: #{$btnDestructiveTextColor};
        --bs-btn-active-bg: #{$btnDestructiveBgColor};
        --bs-btn-active-border-color: #{$btnDestructiveBgColor};
        --bs-btn-active-shadow: inset 0 3px 5px #00000020;
        --bs-btn-disabled-color: #{$btnDestructiveTextColor};
        --bs-btn-disabled-bg: #{$btnDestructiveBgColor};
        --bs-btn-disabled-border-color: #{$btnDestructiveBgColor};
        --bs-btn-disabled-opacity: 0.5;
    }

    .table {
        --bs-table-hover-bg: #f4f4f5;
        @include typography(small-text);

        th,
        td {
            padding: 16px;
            vertical-align: middle;
        }

        th {
            @include typography(small-text-strong);
            color: $tableHeaderTextColor;
        }

        tr:last-child > td {
            border-bottom: hidden;
        }
    }

    // Modals
    .modal {
        --bs-modal-header-border-width: 0;
        --bs-modal-footer-border-width: 0;

        .modal-title {
            @include typography(h5);
        }

        .modal-dialog {
            width: 380px;
            margin: auto;
        }

        .modal-header {
            padding-bottom: $spacer1;
        }

        .modal-body {
            @include typography(small-text);
            padding-top: 0;
            padding-bottom: 0;
            color: $modalBodyTextColor;
        }

        .modal-footer {
            display: flex;
            justify-content: space-between;
        }
    }

    // Badges
    .badge {
        @include typography(tiny-text);
        padding: 2px 10px;

        // !important required on the badge styles to override bootstrap styles which also use !important
        &.border-light {
            border-color: $primaryColor !important;
        }

        &.bg-dark {
            background-color: $primaryColor !important;
        }

        &.bg-light {
            background-color: $secondaryColor !important;
        }

        &.text-dark {
            color: $primaryColor !important;
        }
    }

    // Form inputs
    .form-label {
        @include typography(small-text-strong);
    }

    .form-control {
        @include typography(small-text);
        margin-bottom: 8px;
        padding: 8px 12px;

        &:focus {
            border-color: $formControlBorderColor;
            box-shadow: $focusBoxShadow;
        }
    }

    .form-text {
        @include typography(tiny-text);
        color: $secondaryTextColor;
    }

    .input-with-icon {
        position: relative;

        .form-control {
            padding-left: $inputPadding + $iconWidth + $iconMarginRight;
        }
    }

    .input-icon {
        position: absolute;
        inset: 10px 16px 10px $inputPadding;
        display: flex;
        align-content: center;
        width: $iconWidth;
        height: $iconWidth;
    }

    .form-select {
        @include typography(small-text);
        padding-top: 10px;
        padding-bottom: 10px;

        // override the chevron colour
        /* stylelint-disable-next-line function-url-quotes */
        background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="%2371717A" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6"/%3e%3c/svg%3e');

        &:focus {
            border-color: $formControlBorderColor;
            box-shadow: $focusBoxShadow;
        }

        &.noSelection {
            color: $preSelectTextColor;

            option {
                color: var(--bs-body-color);
            }
        }
    }

    // Navigation
    .navbar {
        background-color: $primaryColor;
        border-bottom: 1px solid $navBorderColor;
    }

    .navbar-toggler {
        background-color: $whiteColor;

        &.collapsed {
            align-self: center;
            width: 42px;
            height: 42px;
            padding: 6px;
        }
    }

    .navbar-toggler-icon {
        margin: 0 auto !important;
    }

    .navbar-brand:focus-visible {
        border-radius: 6px;
        outline: none;
        box-shadow: $focusBoxShadow;
    }

    .nav-link {
        @include typography(body-text-strong);
        color: $whiteColor;
        transition: background-color 150ms ease;

        &:not(.dropdown) {
            --bs-navbar-nav-link-padding-x: 12px;
        }
    }

    .nav-link.is-nav-active {
        background-color: $tertiaryColor;
    }

    .nav-link:focus-visible {
        box-shadow: $focusBoxShadow;
    }

    .dropdown:has(:focus-visible) {
        box-shadow: $focusBoxShadow;
    }

    .dropdown-name {
        @include typography(small-text-strong);
        min-width: 120px;
        color: $blackColor;
    }

    .dropdown-toggle:focus {
        box-shadow: none;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-item {
        display: flex;
        align-items: center;
    }

    .dropdown-item:focus-visible {
        border-radius: 6px;
        outline: none;
        box-shadow: $focusBoxShadow;
    }

    .dropdown-menu.show {
        width: 100%;
    }
}

body {
    $bodyBgColor: #f2eef2;

    $anchorColor: #18181b;
    $anchorHoverColor: #444346;
    /* stylelint-disable-next-line value-keyword-case */
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
    background-color: $bodyBgColor;

    a {
        color: $anchorColor;

        /*
         * Highlight all links in bold (regardless of size) to show they are different from the surrounding content
         * Linting disabled because we shouln't be setting bold on elements directly in almost all cases
         */
        font-weight: 500; // stylelint-disable-line property-disallowed-list
        text-decoration: none;

        &:hover {
            color: $anchorHoverColor;
        }
    }
}

h1 {
    @include typography(h1);
}

h2 {
    @include typography(h2);
}

h3 {
    @include typography(h3);
}

h4 {
    @include typography(h4);
}

h5 {
    @include typography(h5);
}

h6 {
    @include typography(h6);
}

// Override default bootstrap breadcrumb styles
.breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 0;
    --bs-breadcrumb-bg: ;
    --bs-breadcrumb-border-radius: ;
    --bs-breadcrumb-divider-color: $secondaryTextColor;
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: $secondaryTextColor;
    $anchorColor: colour(Melon500);
    $anchorHoverColor: colour(Melon700);

    @include typography(small-text);

    .breadcrumb-item {
        a {
            color: $anchorColor;
            text-decoration: underline;
            cursor: pointer;
            transition: color 0.2s;

            &:hover {
                color: $anchorHoverColor;
            }
        }

        .active {
            text-decoration: none;
        }
    }
}

.pineapple-loader-center {
    position: fixed;
    inset: 0;
    z-index: 999;
    display: grid;
    align-content: center;
    justify-content: center;

    .pineapple-svg {
        // Deliberrately this size so it is the same size as dash's which we can't easily control
        height: 4em;
    }
}

.sharesies-open-logo {
    width: 42px;
    height: 42px;
}

// Designed to match boostrap forms when a field is invalid
.custom-field-invalid-feedback {
    @include typography(small-text);
    width: 100%;
    margin-top: 0.25rem;
    color: $boostrapInvalid;
}
