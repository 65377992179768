/* stylelint-disable property-disallowed-list */
@font-face {
    font-weight: 400;
    font-family: inter;
    font-style: normal;
    src: url(../fonts/inter/Inter-Regular.woff2) format('woff2');
}

@font-face {
    font-weight: 500;
    font-family: inter;
    font-style: normal;
    src: url(../fonts/inter/Inter-Medium.woff2) format('woff2');
}

@font-face {
    font-weight: 700;
    font-family: inter;
    font-style: normal;
    src: url(../fonts/inter/Inter-Bold.woff2) format('woff2');
}

@font-face {
    font-weight: 800;
    font-family: inter;
    font-style: normal;
    src: url(../fonts/inter/Inter-ExtraBold.woff2) format('woff2');
}
/* stylelint-enable property-disallowed-list */
