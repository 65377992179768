@import '~@design-system/colour-tokens/colour';
@import '~@design-system/spacing-tokens/spacing';
@import '~@design-system/typography-tokens/typography';

// ------------ ACCESSIBILITY HELPERS ------------

%screenreaderOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    clip: rect(0 0 0 0);
}

%screenreaderOnlyFocusable {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    white-space: inherit;
    clip: auto;

    // // Use this like
    // .myClass {
    //     @extend %screenreaderOnly;
    //
    //     &:active,
    //     &:focus {
    //         @extend %screenreaderOnlyFocusable;
    //     }
    // }
}

%focusOutline {
    box-shadow:
        0 0 0 2px semanticColour(PageBackgroundBase),
        0 0 0 4px semanticColour(FocusRing);
}

%focusOutlineWithRadius {
    @extend %focusOutline;
    border-radius: 5px;
}

%unstyleFocusOutline {
    border-radius: 0;
    box-shadow: none;
}

@mixin hasAccess {
    // to add keyboard accessibility styling, use like:
    // .myClass {
    //     // some rules
    //     &:focus {
    //         @include hasAccess {
    //             @extend %focusOutlineWithRadius;
    //             // some other rules
    //         }
    //     }
    // }
    :global(.hasAccess) & {
        @content;
    }
}

@mixin accessibilityButton {
    margin: 0;
    padding: 0;
    color: inherit;
    // make sure default browser button styling doesn't clobber our own styles
    /* stylelint-disable property-disallowed-list */
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    /* stylelint-enable property-disallowed-list */
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &:focus {
        @include hasAccess {
            @extend %focusOutlineWithRadius;
        }
    }
}
