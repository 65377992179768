@import 'vars.modules';
/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        // stylelint-disable-next-line media-query-no-invalid
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
    // else on a new line to keep linter happy
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

// Shared mixin to provide basic layout
@mixin container() {
    width: 100%;
    min-width: 320px;
    margin: 0 auto;
}

@mixin cardContainer() {
    $hrColor: #e4e4e7;
    padding: 0 24px;
    background: var(--background-bg-background, #ffffff);
    border: 1px solid var(--border-border-input, #e4e4e7);
    border-radius: var(--radius-rounded-xl, 12px);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    hr {
        margin: -24px;
        color: $hrColor;
        opacity: 1;
    }
}
